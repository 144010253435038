import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
import ImageHeroBanner from "../components/ImageHeroBanner/ImageHeroBanner"
import BreadCrumb from "../components/BreadCrumb/BreadCrumb"
import ProductCategoryTags from "../components/ProductCategoryTags/ProductCategoryTags"
import ImageWithText from "../components/ImageWithText/ImageWithText"
import ProductListing from "../components/ProductListing/ProductListing"
import ImageCardsLayout from "../components/ImageCardsLayout/ImageCardsLayout"
import { imageConstants } from "../constants/image.constants"
import LandingShopCards from '../components/LandingShopCards/LandingShopCards';
import { gaInternalLink} from '../constants/gtm.constants'
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head";

const ProductList = ({ pageContext }) => {
  const { globalComponents, componentProps, seoData, pageType, dataLayer, apiData } =
    pageContext
  const { additionalData, products, pageTitle, productListingTitle } = apiData || {}
  const [popularIds, categoryHeading] = additionalData ? additionalData?.split('-') : ['', ''];

  let path = []
  let currentPath = ''
  if (typeof window !== "undefined") {
    currentPath = window?.location?.href
    path = window.location?.pathname?.split('/')
  }
  const defaultCategory = path[2];

  
  const componentData = (componentProps || []).reduce((a, b) => {
    if (b && Object.keys(b)[0]) {
      a[Object.keys(b)[0]] = Object.values(b)[0]
    }
    return a
  }, {})

  const { items: navigationCollectionItems } = componentData?.BreadCrumb?.navigationCollection
    || { items: [] }
  const breadCrumbItems = defaultCategory ? {
    items: [
      ...navigationCollectionItems
    ]
  } : { items: navigationCollectionItems };

  if(defaultCategory) {
    breadCrumbItems.items.push({
      link: {
        title: pageTitle,
        url: currentPath,
      }
    })
  }

  // GTM custom dataLayer Object based on environment
  if (typeof window !== "undefined") {
    const dataLayerObj = {
      ...dataLayer,
      page: {
        url: window.location.href,
        title: seoData?.title || seoData?.metaTitle,
      },
      content: {
        type: pageType || "others",
        category: "",
      },
    }
    window.gtmDataLayer = dataLayerObj
  }

  const getRelatedProducts = (relatedProducts = "") => {
    return relatedProducts && relatedProducts.split(",").map(pId => {
      const m = products.find(f => f?.ProductID === pId)
      if (m) {
        const title = m?.ProductTitle || m?.SeoPageName
        return {
          link: {
            url: m?.pageSlug,
            gaEventLabel: title
          },
          image: m?.ProductImage[0] || imageConstants?.productDummyImage,
          title,
          variant: 'RELATED_PRODUCTS_CARD',
          gaEventClass: gaInternalLink,
          gaEventLabel: title
        }
      }
      return null
    }).filter(f => f)
  }

  const _relatedProducts = getRelatedProducts(popularIds)
  const bannerData = { ...(componentData?.ImageHeroBanner || {}) }
  const imageWithTextData = { ...(componentData?.ImageWithText || {}) }
  const productListData = { ...(componentData?.ProductListing || {}) }
  const landingShopCards = { ...(componentData?.LandingShopCards || {}) }

  const relatedProducts = _relatedProducts?.length && _relatedProducts?.map(item =>{
    item.images = {
      imageDesktop : item.image
    };
    item.variant = 'RELATED_PRODUCTS'
    delete item['image'];
    return item;
  })
  const getCategoryData = () =>{
    let defaultCategorObject = {};
    productListData?.productFilter?.filterItemCollection?.items?.forEach((Item) =>{
      if(Object.keys(defaultCategorObject)){
        Item?.itemCollection?.items?.forEach(subItem =>{
          if(subItem.categoryKey == defaultCategory){
            defaultCategorObject = {...subItem, typeName : Item.uniqueKey, groupName : Item.title, canOpen: Item?.canOpen};
          }
        })
      }
    })
    return defaultCategorObject;
  }
  let defaultCategorObject = defaultCategory && getCategoryData();

  return (
    <Layout seoData={seoData} globalComponents={globalComponents} pageType={pageType}>
      <ImageHeroBanner
        {...bannerData}
        // variant={'PRODUCT_CATEGORY_PAGE'}
      />
      <div
        className="flex flex-col items-center"
        style={{
          backgroundImage: `url(${imageConstants?.productDetailBg?.url})`
        }}
      >
        <div
          className="flex flex-col w-full mx-auto"
        >
          <div className="max-w-[1170px] w-full mx-auto">
            <BreadCrumb
              variant={'PRODUCT_DETAIL'}
              navigationCollection={breadCrumbItems}
            />
          </div>
          
          <ProductCategoryTags
            {...(componentData?.ProductCategoryTags || {})}
          />
          <ImageWithText
            {...imageWithTextData}
            // variant={'ALL_PRODUCT_LEFT_TEXT_CONTAINER'}
          />
          <ProductListing
            {...productListData}
            heading={defaultCategory ? defaultCategory : productListData?.heading}
            productList={products || []}
            categoryData={defaultCategorObject || {}}
            productListingTitle={productListingTitle || pageTitle}
          />
        </div>
        {
          relatedProducts?.length > 0 ? (
            <div
              className="flex flex-col items-center bg-no-repeat bg-bottom w-[100%] relatedProducts"
            >
              <ImageCardsLayout
                title={categoryHeading}
                cardsCollection={{
                  items: relatedProducts || []
                }}
                variant={'RELATED_PRODUCTS'}
              />
            </div>
          ) : null
        }
        {
          landingShopCards?.cardsCollection?.items?.length > 0 && <LandingShopCards {...landingShopCards} />
        }
      </div>
    </Layout>
  )
}
export const Head = () => {
  const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
  return (
    <>
      <script
        id="first-unique-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
        }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
      />
    </>
  )
}

ProductList.propTypes = {
  pageContext: PropTypes.shape({
    globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    componentProps: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    pageType: PropTypes.string,
    dataLayer: PropTypes.object,
    seoData: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      ogTitle: PropTypes.string,
      ogDescription: PropTypes.string,
      ogType: PropTypes.string,
      twitterTitle: PropTypes.string,
      twitterDescription: PropTypes.string,
      canonicalLink: PropTypes.string,
      metaTitle: PropTypes.string,
      featuredImage: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    apiData: PropTypes.object
  }),
}

export default ProductList
